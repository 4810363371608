import styled from '@odo/lib/styled';
import { FaTimes as IconRemove, FaSearch as IconSearch } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import type { InputHTMLAttributes, KeyboardEvent } from 'react';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { Label } from '@odo/components/elements/form-fields/shared-styles';
import Divider from '@odo/components/elements/divider';
import { Flex } from '@odo/components/elements/layout/flex';
import Loading from '@odo/components/elements/loading';
import { conditionalJoin } from '@odo/utils/string';
import { cssColor } from '@odo/utils/css-color';
import { autoUpdate, computePosition } from '@floating-ui/dom';
/**
 * NOTE: despite what import cost says,
 * this doesn't seem to add to our bundle size
 * coz react-dom is always bundled IN FULL.
 */
import { createPortal } from 'react-dom';

const SearchableDropdownWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  gap: 3px;
`;

const InputWrapper = styled.div`
  padding: 0 4px;
  border: 1px solid #c2c2c2;
  color: ${cssColor('text')};
  background: ${cssColor('background')};
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  letter-spacing: 0.02em;
  transition: border-color 0.2s;

  &.focused {
    border-color: ${cssColor('palette-blue')};
  }

  &.disabled {
    background: #f5f5f5;
    cursor: not-allowed;
  }

  &.error {
    border-color: ${cssColor('palette-pink')};
  }

  &.match-rp-styles {
    background: #f5f5f5;

    &.disabled {
      background: white;
    }
  }

  &.results-opened {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
  }

  &.has-selected-options {
    padding-bottom: 4px;
  }
`;

const Input = styled.input`
  border: none;
  background: none;
  outline: none;
  text-indent: 5px;
  flex-grow: 1;
  padding: 10px 0;
  color: ${cssColor('text')};
`;

const SelectedOption = styled.div`
  border-radius: 3px;
  border: 1px solid #949494;
  padding: 6px 0px 6px 6px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: start;
  background: hsl(210deg 35% 93%);
`;

const SelectedOptionRemove = styled.button`
  padding: 4px 8px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  /* NOTE: the top, left, and width are all overridden by floating-ui computePosition */
  top: calc(100% - 2px);
  left: 0;
  width: 100%;
  z-index: 2;
  overflow: hidden;

  .background {
    background: ${cssColor('background')};
    border-radius: 0 0 4px 4px;
    border: 1px solid #c2c2c2;
    border-top: none;
    z-index: 2;
    overflow: hidden;
    &.match-rp-styles {
      background: #f5f5f5;
    }
    &.focused {
      border-color: ${cssColor('palette-blue')};
    }
  }

  ul {
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0px;
    list-style-type: none;
    max-height: 350px;
    overflow-y: scroll;
  }

  li {
    padding: 6px 10px;
    cursor: pointer;
    color: ${cssColor('text')};

    &:hover {
      background: hsl(210deg 45% 93%);
      color: ${cssColor('background')};
    }

    &.active {
      background: hsl(210deg 71% 89%);
      color: ${cssColor('background')};
    }
  }
`;

interface SearchableDropdownProps<T>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onSearch' | 'onSelect'> {
  onSearch: (term: string) => void;
  options: T[];
  renderOption: (option: T) => JSX.Element;
  onSelect: (option: T) => void;
  selectedOptions: T[];
  renderSelectedOption: (option: T) => JSX.Element;
  onRemove: (option: T) => void;
  label: string;
  required?: boolean;
  loading?: boolean;
  error?: string | boolean;
  closeOnSelect?: boolean;
  matchRPStyles?: boolean;
}

/**
 * TODO: idea for improving:
 * - add a chevron down/up for expanding/collapsing the options list
 */
const SearchableDropdown = <T extends { id: number | string }>({
  onSearch,
  options,
  renderOption,
  onSelect,
  selectedOptions,
  renderSelectedOption,
  onRemove,
  label,
  required,
  loading,
  error,
  matchRPStyles,
  onChange,
  onFocus,
  onKeyDown,
  closeOnSelect,
  ...restProps
}: SearchableDropdownProps<T>) => {
  const id = useId();

  const [isFocused, setIsFocused] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [waitingToShowOptions, setWaitingToShowOptions] = useState(false);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const optionsWrapperRef = useRef<HTMLDivElement | null>(null);
  const optionsRef = useRef<HTMLUListElement | null>(null);
  const navIndexRef = useRef<number | undefined>();

  const keyboardNavigation = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown && onKeyDown(e);

      if (e.key === 'Escape') {
        setShowOptions(false);
        return;
      }

      if (e.key === 'Tab') {
        setIsFocused(false);
        setShowOptions(false);
        return;
      }

      if (!showOptions && ['ArrowDown', 'ArrowUp'].includes(e.key)) {
        setShowOptions(true);
      }

      if (options.length === 0 || !optionsRef.current) return;

      if (e.key === 'Enter' && typeof navIndexRef.current !== 'undefined') {
        // select active option
        const option = options[navIndexRef.current];
        if (option) {
          e.preventDefault();
          onSelect(option);
          if (closeOnSelect) setShowOptions(false);
        }
      } else if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
        e.preventDefault();

        const up = e.key === 'ArrowUp';
        const optionElements = optionsRef.current.querySelectorAll('.option');

        if (typeof navIndexRef.current === 'undefined') {
          // first index in direction
          navIndexRef.current = up ? options.length - 1 : 0;
        } else {
          // clear prev element
          const prevActiveElement = optionElements.item(navIndexRef.current);
          if (prevActiveElement) prevActiveElement.classList.remove('active');

          // next index in direction or overflow to the opposite end
          navIndexRef.current =
            up && navIndexRef.current === 0
              ? options.length - 1
              : !up && navIndexRef.current === options.length - 1
              ? 0
              : navIndexRef.current + (up ? -1 : 1);
        }

        const nextActiveElement = optionElements.item(navIndexRef.current);
        if (nextActiveElement) {
          nextActiveElement.classList.add('active');
          nextActiveElement.scrollIntoView({ block: 'nearest' });
        }
      }
    },
    [showOptions, onKeyDown, options, onSelect, closeOnSelect]
  );

  useEffect(() => {
    if (waitingToShowOptions && options.length > 0) {
      setShowOptions(true);
      setWaitingToShowOptions(false);
    }
  }, [options, waitingToShowOptions]);

  useEffect(() => {
    if (!showOptions) navIndexRef.current = undefined;
  }, [showOptions]);

  /**
   * Placement bindings.
   */
  useEffect(() => {
    if (!showOptions || !wrapperRef.current || !optionsWrapperRef.current) {
      return;
    }

    const anchor = wrapperRef.current;
    const options = optionsWrapperRef.current;

    const cleanup = autoUpdate(anchor, options, () => {
      computePosition(anchor, options, {
        placement: 'bottom-start',
      }).then(({ x, y }) => {
        const width = anchor.getBoundingClientRect().width;
        /**
         * TODO: look into using transform properties instead:
         * @see https://floating-ui.com/docs/misc#subpixel-and-accelerated-positioning
         */
        Object.assign(options.style, {
          left: `${x}px`,
          top: `${y}px`,
          width: `${width}px`,
        });
      });
    });

    return () => cleanup();
  }, [showOptions]);

  useEffect(() => {
    const outsideClick = (e: MouseEvent | TouchEvent) => {
      if (
        wrapperRef.current &&
        e.target instanceof Element &&
        !wrapperRef.current.contains(e.target)
      ) {
        setIsFocused(false);
        setShowOptions(false);
      }
    };

    /**
     * NOTE: using mousedown instead of click because we need this to fire before the click event of the remove button.
     * this is because removing a selected option also removes it's elements from the wrapper, making contains fail.
     */
    document.addEventListener('mousedown', outsideClick);
    document.addEventListener('touchstart', outsideClick);
    return () => {
      document.removeEventListener('mousedown', outsideClick);
      document.removeEventListener('touchstart', outsideClick);
    };
  }, []);

  return (
    <SearchableDropdownWrapper
      ref={wrapperRef}
      onClick={() =>
        !closeOnSelect &&
        isFocused &&
        wrapperRef.current &&
        wrapperRef.current.querySelector('input')?.focus()
      }
      className={conditionalJoin([
        ['focused', isFocused],
        ['error', !!error],
        ['match-rp-styles', !!matchRPStyles],
      ])}
    >
      <Label
        htmlFor={id}
        className={conditionalJoin([
          ['focused', isFocused],
          ['error', !!error],
        ])}
      >
        {label}
        {required && <span>*</span>}
      </Label>

      <InputWrapper
        className={conditionalJoin([
          ['focused', isFocused],
          ['has-selected-options', selectedOptions.length > 0],
          ['results-opened', showOptions],
          ['match-rp-styles', !!matchRPStyles],
        ])}
      >
        <Flex justifyContent="space-between" alignItems="center" gap={2}>
          <Input
            id={id}
            autoComplete="off"
            {...restProps}
            onChange={e => {
              if (e.target.value === '') {
                setShowOptions(false);
              } else {
                navIndexRef.current = undefined;
                setWaitingToShowOptions(true);
                onSearch(e.target.value);
              }

              onChange && onChange(e);
            }}
            onFocus={e => {
              setIsFocused(true);
              options.length > 0 && setShowOptions(true);
              onFocus && onFocus(e);
            }}
            onClick={() => options.length > 0 && setShowOptions(true)}
            // navigate and select options with keyboard
            onKeyDown={keyboardNavigation}
          />

          {!loading && (
            <div style={{ paddingRight: '4px' }}>
              <IconSearch />
            </div>
          )}

          {loading && (
            <div style={{ paddingRight: '10px' }}>
              <Loading isLoading size={15} />
            </div>
          )}
        </Flex>

        {selectedOptions.length > 0 && (
          <>
            <Divider />

            <Flex flexDirection="column" gap={1} mt={1}>
              {selectedOptions.map(selectedOption => (
                <SelectedOption key={selectedOption.id}>
                  {renderSelectedOption(selectedOption)}

                  <SelectedOptionRemove
                    onClick={() => onRemove(selectedOption)}
                    tabIndex={-1}
                  >
                    <IconRemove />
                  </SelectedOptionRemove>
                </SelectedOption>
              ))}
            </Flex>
          </>
        )}
      </InputWrapper>

      {createPortal(
        <OptionsWrapper ref={optionsWrapperRef}>
          <AnimatePresence>
            {showOptions && (
              <motion.div
                className={conditionalJoin([
                  'background',
                  ['focused', isFocused],
                  ['match-rp-styles', !!matchRPStyles],
                ])}
                transition={{ type: 'linear', duration: 0.2 }}
                initial={{ y: -10, opacity: 1 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
              >
                <Flex px={1}>
                  <Divider background="transparent" />
                </Flex>

                <ul ref={optionsRef}>
                  {options.map(option => (
                    <li
                      key={option.id}
                      className="option"
                      onClick={() => {
                        onSelect(option);
                        if (closeOnSelect) {
                          setIsFocused(false);
                          setShowOptions(false);
                        }
                      }}
                    >
                      {renderOption(option)}
                    </li>
                  ))}

                  {options.length === 0 && <li>No results found...</li>}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </OptionsWrapper>,
        document.body
      )}
    </SearchableDropdownWrapper>
  );
};

export default SearchableDropdown;
