import type { SetStateAction } from 'react';
import { createContext } from 'react';
import type { EditorProductInterface } from '@odo/types/portal';
import type { ProductContextType } from './types';

const defaultProductContext: ProductContextType = {
  product: {},
  setProduct: (_product: SetStateAction<EditorProductInterface>) => void 0,
};

const ProductContext = createContext<ProductContextType>(defaultProductContext);

export default ProductContext;
