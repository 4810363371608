import { createContext } from 'react';
import type { DialogContextType, DialogType } from './types';

const defaultContext: DialogContextType = {
  setDialog: (_dialog: DialogType) => void 0,
  removeDialog: (_id: DialogType['id']) => void 0,
};

const DialogContext = createContext<DialogContextType>(defaultContext);

export default DialogContext;
