import { autoUpdate, computePosition } from '@floating-ui/dom';
import styled from '@odo/lib/styled';
import { useRef, useState, useCallback, useEffect } from 'react';
/**
 * NOTE: despite what import cost says,
 * this doesn't seem to add to our bundle size
 * coz react-dom is always bundled IN FULL.
 */
import { createPortal } from 'react-dom';

interface DropdownInputProps<T> {
  onInput: (query: string) => void;
  onSelect: (result: T) => void;
  renderOption: (result: T) => JSX.Element;
  isOptionSelected?: (option: T) => boolean;
  loading?: boolean;
  results: T[];
  selectedOption?: string;
  disabled?: boolean;
}

const DropdownInput = <T extends { id: number }>({
  onInput,
  onSelect,
  renderOption,
  results,
  selectedOption,
  disabled,
}: DropdownInputProps<T>) => {
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);
  const optionsRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        e.target instanceof Element &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const selectOption = useCallback(
    (option: T) => {
      onSelect(option);
      setIsOpen(false);
    },
    [onSelect]
  );

  /**
   * Placement bindings.
   */
  useEffect(() => {
    if (!isOpen || !ref.current || !optionsRef.current) return;

    const anchor = ref.current;
    const options = optionsRef.current;

    const cleanup = autoUpdate(anchor, options, () => {
      computePosition(anchor, options, {
        placement: 'bottom',
      }).then(({ x, y }) => {
        /**
         * TODO: look into using transform properties instead:
         * @see https://floating-ui.com/docs/misc#subpixel-and-accelerated-positioning
         */
        Object.assign(options.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    });

    return () => cleanup();
  }, [isOpen]);

  return (
    <SearchContainer disabled={disabled}>
      <div className="selected-value">
        {isOpen ? (
          <input
            ref={ref}
            className="search"
            placeholder="Search..."
            type="search"
            disabled={disabled}
            defaultValue={inputValue}
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value);
              onInput(e.target.value);
            }}
            onKeyDown={e => {
              if (e.key === 'ArrowDown') {
                setHighlightedIndex(idx =>
                  idx < results.length ? idx + 1 : idx
                );
              } else if (e.key === 'ArrowUp') {
                setHighlightedIndex(idx => (idx > 1 ? idx - 1 : idx));
              } else if (['Enter', 'Space'].includes(e.code)) {
                const select = results[highlightedIndex - 1];
                if (select) {
                  selectOption(select);
                  e.preventDefault();
                }
              }
            }}
          />
        ) : (
          <input
            onFocus={() => setIsOpen(true)}
            placeholder="Search..."
            disabled={disabled}
            value={selectedOption}
            onChange={e => {
              onInput(e.target.value);
            }}
          />
        )}
      </div>
      <div className={['arrow', ...(isOpen ? ['open'] : [])].join(' ')} />

      {createPortal(
        <OptionsContainer ref={optionsRef} className={isOpen ? 'open' : ''}>
          {results.map((option, idx) => (
            <li
              key={option.id}
              value={option.id}
              onClick={() => selectOption(option)}
              className={`option ${
                idx + 1 === highlightedIndex ? 'selected' : ''
              }`}
            >
              {renderOption(option)}
            </li>
          ))}

          {results.length === 0 && (
            <li className="option" onClick={() => setIsOpen(false)}>
              No results found
            </li>
          )}
        </OptionsContainer>,
        document.body
      )}
    </SearchContainer>
  );
};

interface DropdownProps {
  disabled?: boolean;
}

const SearchContainer = styled.div<DropdownProps>`
  position: relative;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  .arrow {
    border-color: #999 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    z-index: 10;
    right: 10px;
    top: 10px;
    width: 0;
  }
  .arrow.open {
    border-color: ${props =>
      props.disabled
        ? 'hsl(0, 0%, 60%) transparent transparent'
        : 'transparent transparent hsl(0, 0%, 60%)'};
    border-width: ${props => (props.disabled ? '5px 5px 0' : '0 5px 5px')};
  }

  .search {
    background-repeat: no-repeat;
    background-position: center;
    outline: 0;
    &::-webkit-search-cancel-button {
      position: absolute;
      right: 0px;
      z-index: 999;
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      background-image: url('../assets/images/svg/close-icon.svg');
      background-color: white;
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .selected-value &:disabled input {
    display: none;
  }

  .selected-value input {
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: visible;
    line-height: 1;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};
    outline: none;
    min-height: 27.5px;
    padding: 2px 20px 2px 10px;
    transition: all 200ms ease;
    width: 100%;

    &::placeholder {
      color: #8c8c8c;
    }
    &:disabled {
      background: #f5f5f5;
      cursor: not-allowed;
    }
    .disabled {
      border: 1px solid rgba(118, 118, 118, 0.3);
      cursor: not-allowed;
      background: #f6f6f6;
    }
    &:focus,
    &:focus-visible {
      border-color: var(--brand-primary);
    }
  }
  .selected-value input[disabled] {
    border: 1px solid rgba(118, 118, 118, 0.3);
    background-color: #f6f6f6;
    cursor: not-allowed;
  }
`;

const OptionsContainer = styled.ul<DropdownProps>`
  display: ${props => (props.disabled ? 'none' : 'none')};
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: 0px;
  max-height: 220px;
  width: 250px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  &.open {
    display: ${props => (props.disabled ? 'none' : 'unset')};
    padding-inline-start: 0px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li.option {
      box-sizing: border-box;
      color: rgba(51, 51, 51, 0.8);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 8px 10px;
      border-bottom: 1px solid #c2c2c2;
      &.text-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
    .option.selected,
    .option:hover {
      background-color: #f2f9fc;
      color: #333;
    }
  }
`;

export default DropdownInput;
