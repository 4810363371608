import { Input } from '@odo/components/elements/form-fields';
import { Flex, Grid } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import {
  SKU_FIELD_ID,
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import { informationSectionValidators } from '@odo/screens/deal/editor/product/validators';
import { SkuAvailability } from '@odo/types/portal';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import { ReactComponent as IconSpinner } from '@odo/assets/svg/tube-spinner.svg';
import { cssColor } from '@odo/utils/css-color';
import type { FunctionComponent, SVGProps } from 'react';

const SkuStatus = ({
  icon: Icon,
  color,
  message,
}: {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  color: string;
  message: string;
}) => (
  <Flex pl={1} gap={2}>
    <Icon color={color} width={16} height={16} />
    <Text color={color} fontWeight={700}>
      {message}
    </Text>
  </Flex>
);

const ProductInformationSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    informationSectionValidators
  ).status;

  return (
    <SectionWrapper title="Product Information - TBC" status={status}>
      <Flex flexDirection="column" gap={1}>
        <Input
          label="SKU"
          autoComplete="off"
          value={currentProduct.sku || ''}
          onChange={e => {
            const value = e.target.value;
            change({
              fieldId: SKU_FIELD_ID,
              label: 'SKU',
              apply: to => (to.sku = value),
            });
          }}
          required
          selectOnFocus
        />

        {currentProduct.skuAvailability === SkuAvailability.owned && (
          <SkuStatus
            icon={IconValid}
            color={cssColor('palette-turquoise')}
            message="SKU is already saved in magento."
          />
        )}
        {currentProduct.skuAvailability === SkuAvailability.available && (
          <SkuStatus
            icon={IconValid}
            color={cssColor('palette-turquoise')}
            message="SKU is available."
          />
        )}
        {currentProduct.skuAvailability === SkuAvailability.taken && (
          <SkuStatus
            icon={IconExclamation}
            color={cssColor('palette-pink')}
            message="SKU already in use, please select another."
          />
        )}
        {currentProduct.skuAvailability ===
          SkuAvailability.validationFailed && (
          <SkuStatus
            icon={IconExclamation}
            color={cssColor('palette-yellow')}
            message="Could not validate SKU, please try again."
          />
        )}
        {currentProduct.skuAvailability === SkuAvailability.checking && (
          <SkuStatus
            icon={IconSpinner}
            color={cssColor('palette-blue')}
            message="Checking availability..."
          />
        )}
      </Flex>
    </SectionWrapper>
  );
};

const ProductDescriptionSection = () => {
  return (
    <SectionWrapper title="Product Description">
      <Text>TBC</Text>
    </SectionWrapper>
  );
};

const ProductSizeInfoSection = () => {
  return (
    <SectionWrapper title="Size Info">
      <Text>TBC</Text>
    </SectionWrapper>
  );
};

const ProductScreen = () => (
  <ErrorBoundary>
    <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gap={[3, 4]}>
      <ProductInformationSection />
      <Flex flexDirection="column" gap={[3, 4]}>
        <ProductDescriptionSection />
        <ProductSizeInfoSection />
      </Flex>
    </Grid>
    <Overscroll />
  </ErrorBoundary>
);

export default ProductScreen;
