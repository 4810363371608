import Card from '@odo/components/elements/card';
import { Grid } from '@odo/components/elements/layout';
import { Heading } from '@odo/components/elements/typography';
import { Status } from '@odo/screens/deal/editor/types';
import type { ReactNode } from 'react';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { cssColor } from '@odo/utils/css-color';
import styled from '@odo/lib/styled';
import { breakpointsEm } from '@odo/contexts/theme/provider';
import { conditionalJoin } from '@odo/utils/string';

const AnimatedIcon = styled.div`
  border-radius: 50%;
  transition: transform 150ms ease;
  transform: scale(0);

  .inner {
    display: flex;
    transition: transform 150ms ease 25ms;
    transform: rotate(-80deg);

    svg {
      width: 24px;
      height: 24px;

      /* NOTE: our SVG props don't support width & height media queries */
      @media screen and (max-width: ${breakpointsEm[0]}em) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.active {
    transform: scale(1);

    .inner {
      transform: rotate(0deg);
    }
  }
`;

const SectionHeading = ({
  children,
  status,
  headerExtras,
}: {
  children: ReactNode;
  status?: Status;
  headerExtras?: ReactNode;
}) => (
  <Grid
    gridTemplateColumns={conditionalJoin([
      '1fr',
      ['auto', !!headerExtras],
      ['auto', !!status],
    ])}
    alignItems="center"
    width="100%"
  >
    <Heading
      fontSize={[2, 3]}
      color={cssColor('text')}
      style={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {children}
    </Heading>

    {!!headerExtras && headerExtras}

    {!!status && (
      <AnimatedIcon className={status === Status.valid ? 'active' : undefined}>
        <div className="inner">
          <IconValid color={cssColor('palette-turquoise')} />
        </div>
      </AnimatedIcon>
    )}
  </Grid>
);

const SectionWrapper = ({
  children,
  title,
  status,
  headerExtras,
}: {
  children: ReactNode;
  title: string;
  status?: Status;
  headerExtras?: ReactNode;
}) => (
  <Card
    header={
      <SectionHeading status={status} headerExtras={headerExtras}>
        {title}
      </SectionHeading>
    }
  >
    <Grid gap="24px">{children}</Grid>
  </Card>
);

export default SectionWrapper;
