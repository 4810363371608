import { cssColor } from '@odo/utils/css-color';
import { Flex } from '@odo/components/elements/layout/flex';
import Button from '@odo/components/elements/button';
import Dropdown from '@odo/components/widgets/dropdown';
import { Heading } from '@odo/components/elements/typography';
import { FaChevronDown as IconDown } from 'react-icons/fa';
// custom icons stolen from elsewhere coz FA icons weren't cutting it
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import type { Validity } from '@odo/screens/deal/editor/helpers';
import type { Validator } from '@odo/screens/deal/editor/types';
import { Status } from '@odo/screens/deal/editor/types';

const Messages = ({
  label,
  messages,
  color,
}: {
  label: string;
  messages: Validator['message'][];
  color: string;
}) => (
  <Flex flexDirection="column" gap={2}>
    <Flex
      pl="2px"
      pr={2}
      pb={1}
      gap={2}
      alignItems="center"
      borderBottom="2px solid"
      borderBottomColor={color}
      width="min-content"
      minWidth="60%"
    >
      <IconExclamation color={color} width={16} height={16} />
      <Heading fontWeight={800} fontSize={[1, 2]} color={color}>
        {label}
      </Heading>
    </Flex>

    <ul
      style={{
        paddingLeft: '20px',
        paddingRight: '4px',
        margin: '0',
      }}
    >
      {messages.map((message, idx) => (
        <li key={idx} style={{ lineHeight: '1.6em', color: cssColor('text') }}>
          {message}
        </li>
      ))}
    </ul>
  </Flex>
);

const StatusButton = ({ validity }: { validity: Validity | undefined }) => {
  if (!validity?.status) return null;

  const { status, errors, warnings } = validity;

  return (
    <Dropdown
      offset={{ crossAxis: 8, mainAxis: 8 }}
      border="1px solid"
      borderColor={cssColor('border')}
      minWidth="200px"
      placement="bottom-end"
      disabled={!(errors.length > 0 || warnings.length > 0)}
      content={() => (
        <Flex px="12px" py="12px" gap={3} flexDirection="column">
          {errors.length > 0 && (
            <Messages
              label="Errors"
              messages={errors}
              color={cssColor('palette-pink')}
            />
          )}

          {warnings.length > 0 && (
            <Messages
              label="Warnings"
              messages={warnings}
              color={cssColor('palette-yellow')}
            />
          )}
        </Flex>
      )}
    >
      <Button
        hue={
          status === Status.valid
            ? 'turquoise'
            : status === Status.error
            ? 'pink'
            : 'yellow'
        }
        variant="flat"
        px={1}
        py={1}
      >
        {status === Status.error && (
          <>
            <IconExclamation width={26} height={26} />
            <IconDown size={14} color={cssColor('grey-blue')} />
          </>
        )}

        {status === Status.warning && (
          <>
            <IconExclamation width={26} height={26} />
            <IconDown size={14} color={cssColor('grey-blue')} />
          </>
        )}

        {status === Status.valid && <IconValid width={28} height={28} />}
      </Button>
    </Dropdown>
  );
};

export default StatusButton;
