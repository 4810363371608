import Button from '@odo/components/elements/button';
import { useChangeProduct } from '@odo/contexts/product-editor';
import {
  FaRegEye as IconEye,
  FaRegEyeSlash as IconEyeClosed,
  FaPhotoVideo as IconImage,
} from 'react-icons/fa';
import { IoIosLink as IconLink } from 'react-icons/io';
import {
  TbTrash as IconTrash,
  TbTrashOff as IconTrashOff,
} from 'react-icons/tb';
import type { EditorProductImage } from '@odo/types/portal';
import {
  getImageSrc,
  isAnchorElement,
  downloadImage,
} from '@odo/screens/deal/editor/images-and-videos/helpers';
import Tooltip from '@odo/components/widgets/tooltip';

const ImageActions = ({ image }: { image: EditorProductImage }) => {
  const change = useChangeProduct();

  return (
    <>
      <Tooltip
        showDelay={1250}
        content={() => (image.isHidden ? 'Include Image' : 'Exclude Image')}
      >
        <Button
          hue="grey"
          variant="flat"
          px={0}
          py={0}
          onClick={() => {
            const shouldHide = !image.isHidden;
            change({
              fieldId: `images.${image.id}.isHidden`,
              label: shouldHide ? 'Exclude Image' : 'Include Image',
              apply: to => {
                to.images = to.images
                  ? to.images.map(i =>
                      i.id === image.id ? { ...i, isHidden: shouldHide } : i
                    )
                  : to.images;
              },
            });
          }}
        >
          {image.isHidden ? <IconEyeClosed size={16} /> : <IconEye size={16} />}
        </Button>
      </Tooltip>

      <Tooltip
        showDelay={1250}
        content={() => (image.shouldDelete ? 'Cancel Delete' : 'Delete Image')}
      >
        <Button
          hue="grey"
          variant="flat"
          px={0}
          py={0}
          onClick={() => {
            const shouldDelete = !image.shouldDelete;
            change({
              fieldId: `images.${image.id}.shouldDelete`,
              label: shouldDelete ? 'Delete Image' : 'Cancel Delete',
              apply: to => {
                to.images = to.images
                  ? to.images.map(i =>
                      i.id === image.id ? { ...i, shouldDelete } : i
                    )
                  : to.images;
              },
            });
          }}
        >
          {image.shouldDelete ? (
            <IconTrashOff size={14} />
          ) : (
            <IconTrash size={14} />
          )}
        </Button>
      </Tooltip>

      <Tooltip showDelay={1250} content={() => 'Download Image'}>
        <Button
          hue="grey"
          variant="flat"
          px={0}
          py={0}
          onClick={() => downloadImage(image)}
        >
          <IconImage size={16} />
        </Button>
      </Tooltip>

      <Tooltip showDelay={1250} content={() => 'Open Image in a New Tab'}>
        <Button hue="grey" variant="flat" px={0} py={0}>
          <a
            href={getImageSrc({ url: image.url, file: image.file })}
            onLoad={e =>
              e.target instanceof Element &&
              isAnchorElement(e.target) &&
              // image urls generated with createObjectURL need to be revoked and will have the `blob:` protocol
              e.target.href.startsWith('blob:') &&
              URL.revokeObjectURL(e.target.href)
            }
            target="_blank"
            rel="noreferrer"
            style={{
              color: 'inherit',
              display: 'inline-flex',
            }}
          >
            <IconLink size={16} />
          </a>
        </Button>
      </Tooltip>
    </>
  );
};

export default ImageActions;
