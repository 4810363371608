/**
 * NOTE: we've created a better currency formatter below,
 * but this one is still used in a few places in RP.
 * TODO: remove once we no longer need it.
 */
export const zarFormatter = (value: number) => `R${value.toFixed(2)}`;

/**
 * NOTE: this is mostly a copy of the APIs `formatMoney` function
 * @see https://bitbucket.org/onedayonly/api/src/ba5acb27b03807b6d594adfa0763b504949a5fc9/app/Traits/Formatters.php?at=master#lines-18:34
 */
export const formatMoney = (
  value: number,
  { decimals = 2, excludeSymbol = false } = {}
) => {
  const parts: string[] = [];

  if (value < 0) parts.push('-');
  if (!excludeSymbol) parts.push('R');

  // NOTE: we're using decimal formatting instead of currency formatting
  // because the currency format adds more fluff than we'd like (eg. a space between the symbol and the value)
  parts.push(
    Intl.NumberFormat('en-ZA', {
      style: 'decimal',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(Math.abs(value))
  );

  return parts.join('');
};
