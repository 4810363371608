import type { ChangeEvent } from 'react';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { dateObjectToIso, daysAway } from '@odo/utils/date';
import type { ApiCategory } from '@odo/types/api';
import {
  CheckboxWrapper,
  HeaderContainer,
  FilterBox,
  FilterContent,
  FilterInput,
  FilterWrapper,
  FormFilter,
  SelectFilter,
} from './filter-styles';
import Button from '@odo/components/elements/button';
import Switch from '@odo/components/elements/switch';
import ShopSearch from '@odo/components/search/shop-search';
import { applyFilterDefault } from '@odo/contexts/search/filters/helpers';
import { Grid } from '@odo/components/elements/layout/grid';

export enum FilterType {
  text = 'text',
  date = 'date',
  select = 'select',
  boolean = 'boolean',
  range = 'range',
  search = 'search',
}

export interface FilterInterface {
  label: string;
  key: string;
  active?: boolean;
  exact?: boolean;
  value?: unknown;
  step?: number;
  defaultValue?: FilterInterface['value'] | (() => FilterInterface['value']);
  type: FilterType;
  options?: { key: string; value: string }[];
}

export const ReadFilterContext = createContext<FilterInterface[]>([]);

type InternalFilter = FilterInterface & { id: number };

const UpdateFilterContext = createContext<
  (args: { id: InternalFilter['id']; filter: Partial<FilterInterface> }) => void
>(_ => null);

let idInc = 0;

const addId = (filter: FilterInterface): InternalFilter => {
  idInc++;
  return { ...filter, id: idInc };
};

const removeId = ({ id: _, ...filter }: InternalFilter): FilterInterface => ({
  ...filter,
});

const FilterText = ({
  filter,
  isActive,
}: {
  filter: InternalFilter;
  isActive: boolean;
}) => {
  const updateFilter = useContext(UpdateFilterContext);

  const [value, setValue] = useState(
    typeof filter.value === 'string' ? filter.value : ''
  );

  return (
    <FilterInput
      type="text"
      value={value}
      disabled={!isActive}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        updateFilter({ id: filter.id, filter: { value: e.target.value } });
        setValue(e.target.value);
      }}
    />
  );
};

const FilterSelect = ({
  filter,
  isActive,
}: {
  filter: InternalFilter;
  isActive: boolean;
}) => {
  const updateFilter = useContext(UpdateFilterContext);

  const [value, setValue] = useState(
    typeof filter.value === 'string' ? filter.value : ''
  );

  return (
    <SelectFilter
      className="custom-arrow"
      value={value}
      disabled={!isActive}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        updateFilter({ id: filter.id, filter: { value: e.target.value } });
        setValue(e.target.value);
      }}
    >
      <option>Please select...</option>
      {(filter.options || []).map(option => (
        <option key={option.value} value={option.value}>
          {option.key}
        </option>
      ))}
    </SelectFilter>
  );
};

const FilterShopSearch = ({
  filter,
  isActive,
}: {
  filter: InternalFilter;
  isActive: boolean;
}) => {
  const updateFilter = useContext(UpdateFilterContext);

  const onResult = useCallback(
    (result: ApiCategory) =>
      updateFilter({ id: filter.id, filter: { value: result.id } }),
    [updateFilter, filter.id]
  );

  return <ShopSearch disabled={!isActive} onResult={onResult} />;
};

const FilterDate = ({
  filter,
  isActive,
  step,
}: {
  filter: InternalFilter;
  isActive: boolean;
  step?: number;
}) => {
  const updateFilter = useContext(UpdateFilterContext);

  const [value, setValue] = useState(() => {
    const filterDefault = applyFilterDefault(filter);
    const defaultValue =
      typeof filterDefault === 'string'
        ? filterDefault
        : dateObjectToIso(daysAway(1), true); // just in case (more for type-safety)

    return !isActive
      ? defaultValue
      : typeof filter.value === 'string'
      ? filter.value
      : filter.value instanceof Date
      ? dateObjectToIso(filter.value, true)
      : defaultValue;
  });

  /**
   * Re-apply default date when deactivated.
   */
  useEffect(() => {
    if (isActive) return;

    const defaultValue = applyFilterDefault(filter);
    if (typeof defaultValue !== 'string') return;

    // updateFilter will trigger a change to filter thus re-running this effect
    // abort if the value is already what we're planning to set it to
    if (
      filter.value instanceof Date &&
      filter.value.getTime() === new Date(defaultValue).getTime()
    ) {
      return;
    }

    setValue(defaultValue);
    updateFilter({
      id: filter.id,
      filter: { value: new Date(defaultValue) },
    });
  }, [filter, updateFilter, isActive]);

  return (
    <FilterInput
      style={{ textIndent: '0' }}
      type="datetime-local"
      max="9999-12-31T23:59:59"
      min="0000-00-00T00:00:00:00"
      value={value}
      step={step}
      disabled={!isActive}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (!isActive) {
          setValue(value);
        } else {
          setValue(e.target.value);
          updateFilter({
            id: filter.id,
            filter: { value: new Date(e.target.value) },
          });
        }
      }}
    />
  );
};

const FilterBoolean = ({
  filter,
  isActive,
}: {
  filter: InternalFilter;
  isActive: boolean;
}) => {
  const updateFilter = useContext(UpdateFilterContext);

  const [value, setValue] = useState(!!filter.value);

  return (
    <SelectFilter
      className="custom-arrow"
      value={value ? '1' : '0'}
      disabled={!isActive}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value === '1' ? true : false;
        updateFilter({ id: filter.id, filter: { value: newValue } });
        setValue(newValue);
      }}
    >
      <option value="1">Enabled</option>
      <option value="0">Disabled</option>
    </SelectFilter>
  );
};

const decimalRegex = /(\d|\.)/g;
const toDecimal = (value: string) => (value.match(decimalRegex) || []).join('');

const FilterRange = ({
  filter,
  isActive,
}: {
  filter: InternalFilter;
  isActive: boolean;
}) => {
  const updateFilter = useContext(UpdateFilterContext);

  const [[fromValue, toValue], setValue] = useState(
    Array.isArray(filter.value) ? filter.value : ['', '']
  );

  return (
    <>
      <FilterInput
        type="text"
        value={fromValue}
        disabled={!isActive}
        className="short-input"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const value = [toDecimal(e.target.value), toValue];
          updateFilter({ id: filter.id, filter: { value } });
          setValue(value);
        }}
      />

      <FilterInput
        type="text"
        value={toValue}
        disabled={!isActive}
        className="short-input"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const value = [fromValue, toDecimal(e.target.value)];
          updateFilter({ id: filter.id, filter: { value } });
          setValue(value);
        }}
      />
    </>
  );
};

const Filter = ({
  filter,
  step,
}: {
  filter: InternalFilter;
  step?: number;
}) => {
  const updateFilter = useContext(UpdateFilterContext);

  const [isActive, setIsActive] = useState(!!filter.active);
  const [isExact, setIsExact] = useState(!!filter.exact);

  return (
    <FilterBox disabled={!isActive}>
      <HeaderContainer>
        <span>{filter.label}</span>
        <CheckboxWrapper>
          <Switch
            checked={isActive}
            onChange={() => {
              updateFilter({ id: filter.id, filter: { active: !isActive } });
              setIsActive(isActive => !isActive);
            }}
          />
        </CheckboxWrapper>
      </HeaderContainer>

      <FilterContent>
        {filter.type === FilterType.text && (
          <FilterText filter={filter} isActive={isActive} />
        )}

        {filter.type === FilterType.select && (
          <FilterSelect filter={filter} isActive={isActive} />
        )}

        {filter.type === FilterType.search && (
          <FilterShopSearch filter={filter} isActive={isActive} />
        )}

        {filter.type === FilterType.date && (
          <FilterDate filter={filter} isActive={isActive} step={step} />
        )}

        {filter.type === FilterType.boolean && (
          <FilterBoolean filter={filter} isActive={isActive} />
        )}

        {filter.type === FilterType.range && (
          <FilterRange filter={filter} isActive={isActive} />
        )}

        {[FilterType.text, FilterType.date].includes(filter.type) && (
          <CheckboxWrapper>
            <span>exact</span>
            <input
              type="checkbox"
              className="checkbox"
              checked={isExact}
              disabled={!isActive}
              onChange={() => {
                updateFilter({ id: filter.id, filter: { exact: !isExact } });
                setIsExact(isExact => !isExact);
              }}
            />
          </CheckboxWrapper>
        )}
      </FilterContent>
    </FilterBox>
  );
};

const Filters = ({
  filters,
  confirm,
}: {
  filters: FilterInterface[];
  confirm: (filters: FilterInterface[]) => void;
  close: () => void;
}) => {
  const [internalFilters, setInternalFilters] = useState(filters.map(addId));

  const setFiltersCallback = useCallback(
    ({
      id,
      filter: newFilter,
    }: {
      id: InternalFilter['id'];
      filter: Partial<FilterInterface>;
    }) => {
      setInternalFilters(filters => [
        ...filters.map(filter => {
          if (filter.id === id) {
            filter = { ...filter, ...newFilter };
          }
          return filter;
        }),
      ]);
    },
    []
  );

  return (
    <Grid>
      <FormFilter
        onSubmit={e => {
          e.preventDefault();
          confirm([...internalFilters.map(removeId)]);
        }}
      >
        <ReadFilterContext.Provider value={internalFilters}>
          <UpdateFilterContext.Provider value={setFiltersCallback}>
            <FilterWrapper>
              {internalFilters.map(filter => (
                <Filter key={filter.id} filter={filter} step={filter.step} />
              ))}
            </FilterWrapper>
          </UpdateFilterContext.Provider>
        </ReadFilterContext.Provider>
        <Button hue="blue" variant="solid" type="submit">
          Let&apos;s go!!
        </Button>
      </FormFilter>
    </Grid>
  );
};

export default Filters;
