import { getGreaterWeight } from '@odo/helpers/calculations/shipping';
import { Status, type Validator } from '@odo/screens/deal/editor/types';
import { isPositiveNumeric } from '@odo/utils/validation';

export const inventorySectionValidators: Validator[] = [
  {
    validate: product =>
      typeof product.originalStock?.number === 'number' &&
      product.originalStock.number > 0,
    message: 'Original stock is required and must be >= 0.',
    status: Status.error,
  },
];

export const shippingSectionValidators: Validator[] = [
  {
    validate: product =>
      !product.isShippingApplied ||
      (isPositiveNumeric(product.width?.number) &&
        isPositiveNumeric(product.height?.number) &&
        isPositiveNumeric(product.length?.number)),
    message:
      'Shipping width, height, and length are required and must be >= 0.',
    status: Status.error,
  },
  {
    validate: product =>
      !product.isShippingApplied || isPositiveNumeric(product.weight?.number),
    message: 'Manual weight is required and must be >= 0.',
    status: Status.error,
  },
  {
    validate: product =>
      product.isShippedIndividually ||
      (getGreaterWeight({
        weight: product.weight?.number || undefined,
        length: product.length?.number || undefined,
        width: product.width?.number || undefined,
        depth: product.height?.number || undefined,
      }) || 0) < 20,
    message:
      'Greater weight is >= 20kg so product should be shipped individually.',
    status: Status.warning,
  },
  {
    validate: product =>
      !product.isShippingApplied ||
      typeof product.shippingCost?.number !== 'number' ||
      isPositiveNumeric(product.shippingCost?.number),
    message: 'Manual shipping cost cannot be < 0.',
    status: Status.error,
  },
  {
    validate: product => !!product.supplierRepacks?.id,
    message: 'You must select a supplier repacks option.',
    status: Status.error,
  },
];

const validators: Validator[] = [
  ...inventorySectionValidators,
  ...shippingSectionValidators,
];

export default validators;
