import { Flex, Grid } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import { useAttributeContext } from '@odo/hooks/attributes';
import { cssColor } from '@odo/utils/css-color';
import type { Dispatch, SetStateAction } from 'react';
import { useState, type ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SideEffects from '@odo/screens/deal/editor/side-effects';
import { ProductEditorProvider } from '@odo/contexts/product-editor';
import { useProduct, useSetProduct } from '@odo/contexts/product-new';
import {
  ProductSource,
  useSetProduct as useOldSetProduct,
} from '@odo/contexts/product';
import { error } from '@odo/utils/toast';
import { queryProduct } from '@odo/graphql/product-new';
import type { ApiCategoryBreadcrumb, ApiCustomOption } from '@odo/types/api';
import { queryCustomOptions } from '@odo/graphql/product/custom-options';
import Card from '@odo/components/elements/card';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { ReactComponent as IconSpinner } from '@odo/assets/svg/tube-spinner.svg';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import { motion, AnimatePresence } from 'framer-motion';
import type { GetProductInterface } from '@odo/types/api-new';
import { produce } from 'immer';
import { getProductToEditorProduct } from '@odo/transformers/product';
import { exportApiLogs } from '@odo/data/api-logs/cache';
import { loadBreadcrumbs } from '@odo/data/product/category';

const PRODUCT_ERROR_TOAST_ID = 'load-product-error';
const CUSTOM_OPTIONS_ERROR_TOAST_ID = 'load-custom-options-error';

interface RawProductData {
  product?: GetProductInterface;
  customOptions?: ApiCustomOption[];
  breadcrumbs?: ApiCategoryBreadcrumb[];
}

enum LoadingMessageStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

interface LoadingMessage {
  id: string;
  label: string;
  status: LoadingMessageStatus;
}

const loadProduct = async ({
  id,
  signal,
  setLoadingMessages,
  setRawProductData,
}: {
  id: string;
  signal: AbortSignal;
  setLoadingMessages: Dispatch<SetStateAction<LoadingMessage[]>>;
  setRawProductData: Dispatch<SetStateAction<RawProductData>>;
}) => {
  let isActive = true;

  signal.addEventListener('abort', () => (isActive = false));

  try {
    const product = await queryProduct({ id });

    // just exit if we've dismounted while the API call was running
    if (!isActive) return;

    if (!product) {
      throw new Error('Failed to load product');
    }

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getProduct'
          ? { ...message, status: LoadingMessageStatus.success }
          : message
      ),
    ]);

    const breadcrumbs = await loadBreadcrumbs({
      categories: product.categories,
      setLoading: (status: boolean) => {
        if (status) {
          setLoadingMessages(messages => [
            ...messages.filter(({ id }) => id !== 'getBreadcrumbs'),
            {
              id: 'getBreadcrumbs',
              label: 'Category Breadcrumbs...',
              status: LoadingMessageStatus.loading,
            },
          ]);
        }
      },
      onComplete: () => {
        setLoadingMessages(messages => [
          ...messages.map(message =>
            message.id === 'getBreadcrumbs'
              ? { ...message, status: LoadingMessageStatus.success }
              : message
          ),
        ]);
      },
      onError: () => {
        setLoadingMessages(messages => [
          ...messages.map(message =>
            message.id === 'getBreadcrumbs'
              ? { ...message, status: LoadingMessageStatus.error }
              : message
          ),
        ]);
      },
    });

    setRawProductData(
      produce(next => {
        next.product = product;
        next.breadcrumbs = breadcrumbs;
        return next;
      })
    );
  } catch (e) {
    if (!isActive) return;

    console.error(e);
    error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : 'Error loading product',
      { id: PRODUCT_ERROR_TOAST_ID, position: 'top-center' }
    );

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getProduct'
          ? { ...message, status: LoadingMessageStatus.error }
          : message
      ),
    ]);
  }
};

const loadCustomOptions = async ({
  id,
  signal,
  setLoadingMessages,
  setRawProductData,
}: {
  id: string;
  signal: AbortSignal;
  setLoadingMessages: Dispatch<SetStateAction<LoadingMessage[]>>;
  setRawProductData: Dispatch<SetStateAction<RawProductData>>;
}) => {
  let isActive = true;

  signal.addEventListener('abort', () => (isActive = false));

  try {
    const customOptions = await queryCustomOptions({ productId: id });

    // just exit if we've dismounted while the API call was running
    if (!isActive) return;

    if (!customOptions) {
      throw new Error('Failed to load custom options');
    }

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getCustomOptions'
          ? { ...message, status: LoadingMessageStatus.success }
          : message
      ),
    ]);

    setRawProductData(
      produce(next => {
        next.customOptions = customOptions;
        return next;
      })
    );
  } catch (e) {
    if (!isActive) return;

    console.error(e);
    error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : 'Error loading custom options',
      { id: CUSTOM_OPTIONS_ERROR_TOAST_ID, position: 'top-center' }
    );

    setLoadingMessages(messages => [
      ...messages.map(message =>
        message.id === 'getCustomOptions'
          ? { ...message, status: LoadingMessageStatus.error }
          : message
      ),
    ]);
  }
};

const EditorRoot = ({ children }: { children: ReactNode }) => {
  const originalProduct = useProduct(); // this is for new deals only...
  const setProduct = useSetProduct();
  const oldSetProduct = useOldSetProduct(); // this is for custom options only (for now)

  const { dealId } = useParams();
  const { isReady: areAttributesLoaded, attributes } = useAttributeContext();

  const [isProductReady, setIsProductReady] = useState(false);
  const [rawProductData, setRawProductData] = useState<RawProductData>({
    product: undefined,
    customOptions: undefined,
    breadcrumbs: undefined,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState<LoadingMessage[]>([
    ...(!areAttributesLoaded
      ? [
          {
            id: 'getAttributes',
            label: 'Attributes...',
            status: LoadingMessageStatus.loading,
          },
        ]
      : []),
  ]);

  useEffect(() => {
    if (areAttributesLoaded) {
      setLoadingMessages(messages => [
        ...messages.map(message =>
          message.id === 'getAttributes'
            ? { ...message, status: LoadingMessageStatus.success }
            : message
        ),
      ]);
    }
  }, [areAttributesLoaded]);

  /**
   * New deal prep.
   */
  useEffect(() => {
    if (dealId) return;

    if (!originalProduct || !originalProduct.isNew) {
      setTimeout(
        () =>
          error(
            'No product data available for creating. Please go back and try again.',
            { position: 'top-center' }
          ),
        250
      );
      return;
    }

    // do we need any data manipulation here, or can we just mark the product data as being ready?
    // depends on what we did with the product data before putting it into the product context...
    // TODO: decide when building the create & duplication
    setIsProductReady(true);
  }, [dealId, originalProduct]);

  /**
   * Existing deal prep.
   */
  useEffect(() => {
    if (!dealId) return;

    const controller = new AbortController();

    // set loading messages
    setLoadingMessages(messages => [
      ...messages.filter(
        ({ id }) => !['getProduct', 'getCustomOptions'].includes(id)
      ),
      {
        id: 'getProduct',
        label: 'Product...',
        status: LoadingMessageStatus.loading,
      },
      {
        id: 'getCustomOptions',
        label: 'Custom Options...',
        status: LoadingMessageStatus.loading,
      },
    ]);

    // running allSettled so that they can all run in parallel and it will wait for them all to finish.
    (async () => {
      await Promise.allSettled([
        loadProduct({
          id: dealId,
          signal: controller.signal,
          setLoadingMessages,
          setRawProductData,
        }),
        loadCustomOptions({
          id: dealId,
          signal: controller.signal,
          setLoadingMessages,
          setRawProductData,
        }),
      ]);
    })();

    return () => controller.abort();
  }, [dealId, setProduct]);

  /**
   * Wait for the product and attributes to be ready, then we can end the loading.
   */
  useEffect(() => {
    if (areAttributesLoaded && isProductReady) {
      setIsLoading(false);
    }
  }, [areAttributesLoaded, isProductReady]);

  /**
   * Wait for attributes and all of our raw product data.
   * Once available transform the data and apply defaults, then set on our contexts.
   */
  useEffect(() => {
    if (
      areAttributesLoaded &&
      rawProductData.product &&
      rawProductData.customOptions
    ) {
      try {
        const editorProduct = getProductToEditorProduct({
          product: rawProductData.product,
          customOptions: rawProductData.customOptions,
          breadcrumbs: rawProductData.breadcrumbs,
          attributes,
        });
        if (!editorProduct) {
          throw new Error('Product data is invalid');
        }

        setProduct(editorProduct);

        // TODO: move the custom options onto our new product context once we fully use the new deal editor
        if (rawProductData.product.id) {
          oldSetProduct({
            // NOTE: we add the product price for our custom options to pull from
            product: { price: rawProductData.product.price },
            source: ProductSource.magento,
            id: rawProductData.product.id,
            customOptions: rawProductData.customOptions,
          });
        }

        // clear our raw product data to save resources
        setRawProductData({
          product: undefined,
          customOptions: undefined,
          breadcrumbs: undefined,
        });

        // it is time
        setIsProductReady(true);
      } catch (e) {
        error(
          e instanceof Error && typeof e.message === 'string'
            ? e.message
            : 'Product data is invalid',
          {
            position: 'top-center',
            messageOptions: {
              action: { label: 'Export Log', callback: exportApiLogs },
            },
          }
        );
      }
    }
  }, [
    rawProductData,
    areAttributesLoaded,
    attributes,
    setProduct,
    oldSetProduct,
  ]);

  if (isLoading) {
    return (
      <Grid justifyContent="center" alignContent="center" height="80vh">
        <Card width="300px" maxWidth="90vw">
          <Heading textAlign="center" color={cssColor('text')}>
            Loading
          </Heading>

          {loadingMessages.length > 0 && (
            <Flex mt={4} flexDirection="column" gap={[2, 3]}>
              <AnimatePresence>
                {loadingMessages.map(message => (
                  <motion.div
                    key={message.id}
                    layout
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 20, opacity: 0 }}
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      gap={2}
                    >
                      <Text color={cssColor('text')}>{message.label}</Text>

                      {message.status === LoadingMessageStatus.loading && (
                        <IconSpinner
                          width={16}
                          height={16}
                          color={cssColor('palette-blue')}
                        />
                      )}

                      {message.status === LoadingMessageStatus.success && (
                        <IconValid
                          width={16}
                          height={16}
                          color={cssColor('palette-turquoise')}
                        />
                      )}

                      {message.status === LoadingMessageStatus.error && (
                        <IconExclamation
                          width={16}
                          height={16}
                          color={cssColor('palette-pink')}
                        />
                      )}
                    </Flex>
                  </motion.div>
                ))}
              </AnimatePresence>
            </Flex>
          )}
        </Card>
      </Grid>
    );
  }

  return (
    <ProductEditorProvider>
      <SideEffects />
      {children}
    </ProductEditorProvider>
  );
};

export default EditorRoot;
