import React from 'react';
import { Deal } from '../../models/Deal.jsx';
import { FormLayout } from './shared/FormLayout.jsx';
import { ProductInformationSection } from './productInformation/ProductInformationSection';
import { ProductDescriptionSection } from './productInformation/ProductDescriptionSection';
import { ProductSizeChartSection } from './productInformation/ProductSizeChartSection';
import { Grid } from '@odo/components/elements/layout/grid';
import Divider from '@odo/components/elements/divider';
import styled from '@odo/lib/styled';

const ProductGrid = styled(Grid)`
  grid-template-columns: 1fr;
  @media screen and (min-width: 80em) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ProductInformationScreen = () => {
  return (
    <FormLayout model={Deal.MODELS.PRODUCT}>
      <ProductGrid>
        <ProductInformationSection />
        <div style={{ overflow: 'auto' }}>
          <ProductDescriptionSection />
          <Divider />
          <ProductSizeChartSection />
        </div>
      </ProductGrid>
    </FormLayout>
  );
};

export default ProductInformationScreen;
