import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { EditorProductInterface } from '@odo/types/portal';
import ProductContext from './context';

const ProductProvider = ({ children }: { children: ReactNode }) => {
  const [product, setProduct] = useState<EditorProductInterface>({});

  const value = useMemo(() => ({ product, setProduct }), [product]);

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};

export default ProductProvider;
