/**
 * NOTE: this is meant to be used as the root import for anything related to styled-system & styled-components.
 * NOTE: cannot alias without either ejecting CRA or adding a new NPM package.
 * TODO: add an alias if we ever eject from CRA: @odo/styled
 */

import {
  createShouldForwardProp,
  props,
} from '@styled-system/should-forward-prop';

// eslint-disable-next-line no-restricted-imports
export * from 'styled-components';
// eslint-disable-next-line no-restricted-imports
export { default } from 'styled-components';
// eslint-disable-next-line no-restricted-imports
export * from 'styled-system';

// new utility functions
export * from './props/gap';
export * from './props/text-indent';

// new utility prop list
const newProps = ['gap', 'columnGap', 'rowGap', 'textIndent'];

/**
 * We don't want all our styled-system props to be added to the DOM.
 * Unfortunately it requires adding a config to each styled-component.
 * We'll declare the config here, but it's up to implementation to use it.
 * That said, if it gets left out, we still shouldn't end up with any problems,
 * just occasionally have a bunch of unnecessary HTML attributes.
 *
 * We unfortunately also need to add all of our new CSS utility function props to that list.
 *
 * @see https://styled-components.com/docs/api#shouldforwardprop
 * @see https://styled-system.com/guides/removing-props-from-html/
 */
const shouldForwardProp = createShouldForwardProp([...props, ...newProps]);

export const styledConfig = { shouldForwardProp };
