import React from 'react';
import { RPSListContainer } from '@rps/web-components/build/react-wrappers';
import { Deal } from '../../models/Deal.jsx';
import { FormLayout } from './shared/FormLayout.jsx';
import { PriceSection } from './priceAndCustomOptions/PriceSection';
import { CustomOptionsSection } from './priceAndCustomOptions/CustomOptionsSection';
import { CalculationSection } from './priceAndCustomOptions/CalculationSection';
import { Grid } from '@odo/components/elements/layout/grid';

const PriceAndCustomOptionsScreen = () => {
  return (
    <FormLayout model={Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS}>
      <RPSListContainer className="vertical">
        <Grid gridTemplateColumns={['1fr', null, '1fr 1fr']}>
          <PriceSection />
          <CalculationSection />
        </Grid>
        <CustomOptionsSection />
      </RPSListContainer>
    </FormLayout>
  );
};

export default PriceAndCustomOptionsScreen;
