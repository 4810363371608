import type { Attribute } from '@odo/contexts/attributes';
import { processVideoUrl } from '@odo/screens/deal/editor/images-and-videos/helpers';
import type { ApiCustomOption } from '@odo/types/api';
import { AttributeCode, type ApiCategoryBreadcrumb } from '@odo/types/api';
import type {
  CreateProductInput,
  GetProductInterface,
  UpdateProductInput,
} from '@odo/types/api-new';
import { isValidImage } from '@odo/types/guards';
import type {
  EditorProductVideo,
  EditorNumericInput,
  EditorCategory,
} from '@odo/types/portal';
import {
  SkuAvailability,
  type EditorProductInterface,
} from '@odo/types/portal';
import { isNewId } from '@odo/utils/uuid';

const getSurchargeIds = ({
  surcharges,
  attributes,
}: {
  surcharges?: GetProductInterface['surcharges'];
  attributes?: Attribute[];
}) => {
  // the set guarantees uniqueness
  const ids = new Set<string>();

  // get all ids from attributes
  attributes
    ?.find(attr => attr.id === AttributeCode.surcharges)
    ?.options.forEach(({ value }) => ids.add(value));
  // get all ids from the product data
  surcharges?.forEach(({ key }) => ids.add(key));

  // return the set as an array
  return Array.from(ids);
};

const getImages = (images: GetProductInterface['images']) =>
  (images || [])
    .filter(isValidImage)
    .map(image => ({
      ...image,
      isHidden: image.excludeImageTypes === 1 ? true : false,
      position: image.position || 0,
    }))
    .sort((a, b) => a.position - b.position);

const getVideos = (videos: GetProductInterface['videos']) => {
  const editorVideos: EditorProductVideo[] = [];

  const matches = videos && videos.match(/<iframe.+?><\/.+?>/g);
  if (matches) {
    let pos = 0;
    const ids: string[] = [];
    matches.forEach(iframe => {
      if (ids.includes(iframe)) return; // we skip duplicates (they will be cleaned up on save)
      ids.push(iframe);
      const srcMatch = iframe.match(/src="(.+?)"/);
      editorVideos.push({
        // NOTE: for easier editing we need a reference ID
        // but this must match when we load the latest data before saving
        // we're gonna use the full iframe string for this, as it's the most unique
        // but that will mean we can't allow a product to have duplicates (unlikely to be an issue)
        id: iframe,
        raw: iframe,
        position: pos++,
        ...(srcMatch &&
          srcMatch[1] && {
            url: srcMatch[1],
            platform: processVideoUrl(srcMatch[1]).platform,
          }),
      });
    });
  }

  return editorVideos;
};

const numberToInput = (num: number | undefined): EditorNumericInput => ({
  string: num?.toString(),
  number: num,
});

/**
 * NOTE: most of these fields aren't actually queried via gql coz we don't use them.
 * But we've got them in the types for future proofing.
 */
const getInventory = (
  i: GetProductInterface['inventory']
): EditorProductInterface['inventory'] =>
  typeof i === 'undefined'
    ? undefined
    : {
        qty: numberToInput(i.qty),
        minQty: numberToInput(i.minQty),
        useConfigMinQty: i.useConfigMinQty,
        isQuantityDecimal: i.isQuantityDecimal,
        isBackorder: i.isBackorder,
        useConfigBackorder: i.useConfigBackorder,
        minSaleQuantity: numberToInput(i.minSaleQuantity),
        useConfigMinSaleQty: i.useConfigMinSaleQty,
        maximumSaleQuantity: numberToInput(i.maximumSaleQuantity),
        useConfigMaxSaleQty: i.useConfigMaxSaleQty,
        isInStock: i.isInStock,
        notifyStockQty: numberToInput(i.notifyStockQty),
        useConfigNotifyStockQty: i.useConfigNotifyStockQty,
        useConfigQuantityIncrement: i.useConfigQuantityIncrement,
        quantityIncrement: numberToInput(i.quantityIncrement),
        isDecimalDivide: i.isDecimalDivide,
        StockConfigQuantityIncrement: numberToInput(
          i.StockConfigQuantityIncrement
        ),
        isApplyMaxSaleQtyToProductOptions: i.isApplyMaxSaleQtyToProductOptions,
        isApplyMaxSaleQtyCustomerProfile: i.isApplyMaxSaleQtyCustomerProfile,
        // isManageStock on get, manageStock on create/update
        useConfigManageStock: i.isManageStock,
        // isEnableQuantityIncrements on get, enableQuantityIncrements on create/update
        useConfigEnableQuantityIncrements: i.isEnableQuantityIncrements,
      };

const splitCategories = (
  categories: GetProductInterface['categories'],
  breadcrumbs?: ApiCategoryBreadcrumb[]
) =>
  (categories || []).reduce(
    (acc, { categoryId, categoryName }) => {
      if (!categoryId) return acc;

      const breadcrumb = breadcrumbs
        ? breadcrumbs.find(b => b.id === categoryId)
        : undefined;

      if (breadcrumb && breadcrumb.type === 'daily_shop') {
        acc.shops.push({ id: categoryId, categoryName, breadcrumb });
      } else if (breadcrumb && breadcrumb.type === 'permanent') {
        acc.permanentShops.push({ id: categoryId, categoryName, breadcrumb });
      } else {
        acc.categories.push({ id: categoryId, categoryName, breadcrumb });
      }

      return acc;
    },
    {
      categories: [] as EditorCategory[],
      shops: [] as EditorCategory[],
      permanentShops: [] as EditorCategory[],
    }
  );

export const getProductToEditorProduct = ({
  product: p,
  attributes,
  breadcrumbs,
}: {
  product: GetProductInterface;
  customOptions?: ApiCustomOption[];
  attributes?: Attribute[];
  breadcrumbs?: ApiCategoryBreadcrumb[];
}): EditorProductInterface | undefined => {
  if (!p.id) {
    throw new Error('API product data is invalid.');
  }

  const editorProduct: EditorProductInterface = {
    id: p.id,
    preview: p.preview,
    isSupplierNew: p.isSupplierNew,
    brand: p.brand,
    sku: p.sku,
    url: p.url,
    name: p.name,
    shortName: p.shortName,
    isDisplayRetail: p.isDisplayRetail,
    isSavingsInRands: p.isSavingsInRands,
    activeFromDate: p.activeFromDate,
    activeToDate: p.activeToDate,
    isAlcoholic: p.isAlcoholic,
    isHygienic: p.isHygienic,
    isParallelImport: p.isParallelImport,
    isFragile: p.isFragile,
    additionalInfo: p.additionalInfo,
    calloutText: p.calloutText,
    lockdownText: p.lockdownText,
    isReturnableToSupplier: p.isReturnableToSupplier,
    warranty: p.warranty,
    leftAdditionalInfo: p.leftAdditionalInfo,
    moreDetails: p.moreDetails,
    status: p.status,
    isLunchtimeProduct: p.isLunchtimeProduct,
    isBestSeller: p.isBestSeller,
    isMainDeal: p.isMainDeal,
    isShippingApplied: p.isShippingApplied,
    isShippedIndividually: p.isShippedIndividually,
    pillOne: p.pillOne,
    pillTwo: p.pillTwo,
    isDeliveredBySupplier: p.isDeliveredBySupplier,
    isPreviewOnly: p.isPreviewOnly,
    surcharge: p.surcharge, // can be left as a number coz we always calculate it
    isSampleReceived: p.isSampleReceived,
    isPhotographedByStudio: p.isPhotographedByStudio,
    xtdDaysRequested: p.xtdDaysRequested,
    xtdDaysConfirmed: p.xtdDaysConfirmed,

    // TODO: inventory, customOptions (maybe)
    inventory: p.inventory ? getInventory(p.inventory) : undefined,

    images: getImages(p.images),

    // TODO: isReferable & features (maybe)

    // custom/extra fields for editing
    skuAvailability: SkuAvailability.owned, // default to owned so that we don't unnecessarily run the checks

    // reformatted fields
    videos: getVideos(p.videos),

    // split the categories into shops, permanentShops, and categories
    ...splitCategories(p.categories, breadcrumbs),

    // numeric fields
    originalStock: numberToInput(p.originalStock),
    price: numberToInput(p.price),
    cost: numberToInput(p.cost),
    retail: numberToInput(p.retail),
    rebateDiscount: numberToInput(p.rebateDiscount),
    width: numberToInput(p.width),
    length: numberToInput(p.length),
    height: numberToInput(p.height),
    weight: numberToInput(p.weight),
    shippingCost: numberToInput(p.shippingCost),

    // completed attributes TODO: any missing attributes
    buyer: p.buyer
      ? {
          id: p.buyer,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.buyer)
            ?.options.find(option => option.value === p.buyer)?.label,
        }
      : undefined,
    salesAssistant: p.salesAssistant
      ? {
          id: p.salesAssistant,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.salesAssistant)
            ?.options.find(option => option.value === p.salesAssistant)?.label,
        }
      : undefined,
    priority: p.priority
      ? {
          id: p.priority,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.priority)
            ?.options.find(option => option.value === p.priority?.toString())
            ?.label,
        }
      : undefined,
    supplier: p.supplier
      ? {
          id: p.supplier,
          // self-executing anonymous function with a return statement
          // to get a variable for second field without repeating loops
          ...(() => {
            const supplierOption = attributes
              ?.find(attr => attr.id === AttributeCode.supplier)
              ?.options.find(option => option.value === p.supplier);
            return {
              label: supplierOption?.label,
              buyer: supplierOption?.metadata?.find(
                meta => meta.key === 'buyer'
              )?.value,
            };
          })(),
        }
      : undefined,
    dealType: p.dealType
      ? p.dealType.map(dealType => ({
          id: dealType,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.dealType)
            ?.options.find(option => option.value === dealType)?.label,
        }))
      : undefined,
    campaign: p.campaign
      ? {
          id: p.campaign,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.campaign)
            ?.options.find(option => option.value === p.campaign)?.label,
        }
      : undefined,
    campaignMailer: p.campaignMailer
      ? p.campaignMailer.map(campaignMailer => ({
          id: campaignMailer,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.campaignMailer)
            ?.options.find(option => option.value === campaignMailer)?.label,
        }))
      : undefined,
    platform: p.platform
      ? p.platform.map(platform => ({
          id: platform,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.platform)
            ?.options.find(option => option.value === platform)?.label,
        }))
      : undefined,
    area: p.area
      ? {
          id: p.area,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.area)
            ?.options.find(option => option.value === p.area)?.label,
        }
      : undefined,
    surcharges: getSurchargeIds({
      surcharges: p.surcharges,
      attributes,
    }).map(id => ({
      id,
      label: attributes
        ?.find(attr => attr.id === AttributeCode.surcharges)
        ?.options.find(option => option.value === id)?.label,
      // self-executing anonymous function with a return statement
      // to get a variable for formatting without repeating work
      value: (() => {
        const val = (p.surcharges || []).find(s => s.key === id)?.value;
        return {
          string: val || '',
          number:
            val === '' || typeof val === 'undefined'
              ? 0
              : val === null
              ? null
              : parseFloat(val),
        };
      })(),
    })),
    warrantyPeriod:
      p.warrantyPeriod && p.warrantyPeriod.toLowerCase() !== 'none'
        ? {
            id: p.warrantyPeriod,
            label: attributes
              ?.find(attr => attr.id === AttributeCode.warrantyPeriod)
              ?.options.find(option => option.value === p.warrantyPeriod)
              ?.label,
          }
        : undefined,
    supplierRepacks:
      p.supplierRepacks && p.supplierRepacks.toLowerCase() !== 'none'
        ? {
            id: p.supplierRepacks,
            label: attributes
              ?.find(attr => attr.id === AttributeCode.supplierRepacks)
              ?.options.find(option => option.value === p.supplierRepacks)
              ?.label,
          }
        : undefined,
    customerDeliveryTime:
      p.customerDeliveryTime && p.customerDeliveryTime.toLowerCase() !== 'none'
        ? {
            id: p.customerDeliveryTime,
            label: attributes
              ?.find(attr => attr.id === AttributeCode.customerDeliveryTime)
              ?.options.find(option => option.value === p.customerDeliveryTime)
              ?.label,
          }
        : undefined,
    adminCost: p.adminCost
      ? {
          id: p.adminCost,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.adminCost)
            ?.options.find(option => option.value === p.adminCost?.toString())
            ?.label,
        }
      : undefined,
  };

  return editorProduct;
};

/**
 * TODO: complete editor product to create product.
 */
export const editorProductToCreateProductInput = (
  product: EditorProductInterface
): CreateProductInput | undefined => {
  return;
};

export const editorProductToUpdateProductInput = (
  p: EditorProductInterface
): UpdateProductInput | undefined => {
  // NOTE: these conditions don't seem to be informing TS that the fields are defined
  // but it should prevent runtime/API errors
  if (
    typeof p.isPhotographedByStudio === 'undefined' ||
    typeof p.isSampleReceived === 'undefined' ||
    (typeof p.inventory !== 'undefined' &&
      typeof p.inventory.maximumSaleQuantity === 'undefined')
  ) {
    throw new Error('Product data cannot be transformed for updating.');
  }

  const updateProductInput: UpdateProductInput = {
    isSupplierNew: p.isSupplierNew,
    brand: p.brand,
    sku: p.sku,
    url: p.url,
    name: p.name,
    shortName: p.shortName,
    isDisplayRetail: p.isDisplayRetail,
    isSavingsInRands: p.isSavingsInRands,
    activeFromDate: p.activeFromDate,
    activeToDate: p.activeToDate,
    isAlcoholic: p.isAlcoholic,
    isHygienic: p.isHygienic,
    isParallelImport: p.isParallelImport,
    isFragile: p.isFragile,
    additionalInfo: p.additionalInfo,
    calloutText: p.calloutText,
    lockdownText: p.lockdownText,
    isReturnableToSupplier: p.isReturnableToSupplier,
    warranty: p.warranty,
    leftAdditionalInfo: p.leftAdditionalInfo,
    moreDetails: p.moreDetails,
    status: p.status,
    isLunchtimeProduct: p.isLunchtimeProduct,
    isBestSeller: p.isBestSeller,
    isMainDeal: p.isMainDeal,
    isShippingApplied: p.isShippingApplied,
    isShippedIndividually: p.isShippedIndividually,
    pillOne: p.pillOne,
    pillTwo: p.pillTwo,
    isDeliveredBySupplier: p.isDeliveredBySupplier,
    isPreviewOnly: p.isPreviewOnly,
    surcharge: p.surcharge,
    xtdDaysRequested: p.xtdDaysRequested,
    xtdDaysConfirmed: p.xtdDaysConfirmed,

    // NOTE: we throw an error if these are undefined, but TS isn't aware here, so we're adding a fallback for safety
    isPhotographedByStudio: p.isPhotographedByStudio || false,
    isSampleReceived: p.isSampleReceived || false,

    // if the one required field from inventory isn't present, we won't send the inventory at all
    // again, we throw an error in this case, but we need the below for TS
    ...(typeof p.inventory?.maximumSaleQuantity?.number !== 'undefined'
      ? {
          inventory: {
            qty: p.inventory.qty?.number,
            minQty: p.inventory.minQty?.number,
            useConfigMinQty: p.inventory.useConfigMinQty,
            isQuantityDecimal: p.inventory.isQuantityDecimal,
            isBackorder: p.inventory.isBackorder,
            useConfigBackorder: p.inventory.useConfigBackorder,
            minSaleQuantity: p.inventory.minSaleQuantity?.number,
            useConfigMinSaleQty: p.inventory.useConfigMinSaleQty,
            maximumSaleQuantity: p.inventory.maximumSaleQuantity.number,
            useConfigMaxSaleQty: p.inventory.useConfigMaxSaleQty,
            isInStock: p.inventory.isInStock,
            notifyStockQty: p.inventory.notifyStockQty?.number,
            useConfigNotifyStockQty: p.inventory.useConfigNotifyStockQty,
            useConfigQuantityIncrement: p.inventory.useConfigQuantityIncrement,
            quantityIncrement: p.inventory.quantityIncrement?.number,
            isDecimalDivide: p.inventory.isDecimalDivide,
            StockConfigQuantityIncrement:
              p.inventory.StockConfigQuantityIncrement?.number,
            isApplyMaxSaleQtyToProductOptions:
              p.inventory.isApplyMaxSaleQtyToProductOptions,
            isApplyMaxSaleQtyCustomerProfile:
              p.inventory.isApplyMaxSaleQtyCustomerProfile,
            // isManageStock on get, manageStock on create/update
            manageStock: p.inventory.useConfigManageStock,
            // isEnableQuantityIncrements on get, enableQuantityIncrements on create/update
            useConfigEnableQuantityIncrements:
              p.inventory.useConfigEnableQuantityIncrements,
          },
        }
      : {}),

    // TODO: customOptions (maybe)

    images: (p.images || [])
      // we only want to send existing images in the update mutation
      // new images will be uploaded separately for now
      .filter(image => !isNewId(image.id) && image.filePath)
      .map(i => ({
        id: i.id,
        position: i.position,
        label: i.label,
        excludeImageTypes: i.isHidden ? 1 : 0,
        imageTypes: i.imageTypes,
        // NOTE: we filter out images without a filePath, but TS doesn't know that
        filePath: i.filePath || '',
        ...(i.shouldDelete && { isDelete: true }),
      })),

    // TODO: isReferable & features (maybe)

    // reformatted fields
    videos: p.videos
      ? p.videos
          .filter(vid => !vid.shouldDelete)
          .sort((a, b) => a.position - b.position)
          .map(vid => vid.raw)
          .join(' ')
      : '',
    categories: [
      ...(p.categories || []),
      ...(p.shops || []),
      ...(p.permanentShops || []),
    ].reduce((acc, value) => {
      // NOTE: we need to convert the ID to a number and validate it
      const id = parseInt(value.id, 10);
      if (!isNaN(id)) {
        acc.push(id);
      }
      return acc;
    }, [] as number[]),

    // numeric fields
    originalStock: p.originalStock?.number,
    price: p.price?.number,
    cost: p.cost?.number,
    retail: p.retail?.number,
    rebateDiscount: p.rebateDiscount?.number,
    width: p.width?.number,
    length: p.length?.number,
    height: p.height?.number,
    weight: p.weight?.number,
    shippingCost: p.shippingCost?.number,

    // attributes TODO: any missing attributes
    buyer: p.buyer?.id,
    salesAssistant: p.salesAssistant?.id,
    priority: p.priority?.id,
    supplier: p.supplier?.id,
    dealType:
      typeof p.dealType !== 'undefined' && p.dealType.length > 0
        ? p.dealType.map(({ id }) => id)
        : [], // need an empty array on update, but `null` on create
    campaign: p.campaign?.id,
    campaignMailer: p.campaignMailer?.map(({ id }) => id),
    platform: p.platform?.map(({ id }) => id),
    area: p.area?.id,
    surcharges: (p.surcharges || []).map(({ id, value }) => ({
      key: id,
      value: value.string || '',
    })),
    warrantyPeriod: p.warrantyPeriod?.id,
    supplierRepacks: p.supplierRepacks?.id,
    customerDeliveryTime: p.customerDeliveryTime?.id,
    adminCost: p.adminCost?.id,
  };

  return updateProductInput;
};
