import styled from '@odo/lib/styled';
import type { ReactNode } from 'react';
import { useContext, useEffect, useId } from 'react';
import { DialogContext } from '@odo/contexts/dialog';
import { Flex } from '@odo/components/elements/layout/flex';
import { Heading } from '@odo/components/elements/typography';
import { cssColor } from '@odo/utils/css-color';
import type { BoxProps } from '@odo/components/elements/layout/box';
import { Box } from '@odo/components/elements/layout/box';
import Button from '@odo/components/elements/button';
import { FaTimes as IconClose } from 'react-icons/fa';

type DialogOuterProps = BoxProps;

const DialogOuter = styled(Box)<DialogOuterProps>``;

DialogOuter.defaultProps = {
  bg: '#fff',
  borderRadius: '1rem',
  boxShadow:
    '1px 2px 4px -2px hsl(240deg 33.33% 80% / 40%), 1px 2px 8px -2px hsl(240deg 33.33% 80% / 20%)',
};

const BaseDialog = ({
  children,
  isOpen,
  close,
  autoFocus,
  labelledById,
  describedById,
  /**
   * TODO: I want to be able to pass the entire DialogOuterProps list as a rest object into the render function.
   * but because it's an object (and cannot be memoized) it causes infinite re-renders.
   *
   * One option is to turn this into a `React.memo` component, and provide a custom deep-equals comparison function:
   * @see https://react.dev/reference/react/memo#specifying-a-custom-comparison-function
   *
   * A decent example can be found here:
   * @see https://codesandbox.io/p/sandbox/react-memo-only-deep-compare-selected-props-1it1x?file=%2Fsrc%2FApp.js%3A76%2C2
   *
   * For now I've gone with the simpler option of supporting the limited list of props that we need.
   * But we also need to hardcode the types as responsive values cannot be supported,
   * because arrays have the same issues as objects.
   */
  minWidth,
  minHeight,
}: {
  children: ReactNode;
  isOpen: boolean;
  close: () => void;
  autoFocus?: boolean;
  labelledById?: string;
  describedById?: string;
  minWidth?: string;
  minHeight?: string;
}) => {
  const id = useId();
  const { setDialog, removeDialog } = useContext(DialogContext);

  /**
   * Set dialog context.
   */
  useEffect(() => {
    setDialog({
      id,
      labelledById,
      describedById,
      isOpen,
      close,
      autoFocus,
      render: () => (
        <DialogOuter minWidth={minWidth} minHeight={minHeight}>
          {children}
        </DialogOuter>
      ),
    });
  }, [
    id,
    isOpen,
    close,
    autoFocus,
    labelledById,
    describedById,
    children,
    setDialog,
    removeDialog,
    minWidth,
    minHeight,
  ]);

  /**
   * Remove dialog from context on dismount.
   */
  useEffect(() => {
    return () => removeDialog(id);
  }, [removeDialog, id]);

  return null;
};

const Dialog = ({
  children,
  title,
  close,
  ...restProps
}: {
  children: ReactNode;
  title: string;
  isOpen: boolean;
  close: () => void;
  autoFocus?: boolean;
  describedById?: string;
  minWidth?: string;
  minHeight?: string;
}) => {
  const labelId = useId();

  return (
    <BaseDialog labelledById={labelId} close={close} {...restProps}>
      <Flex
        backgroundColor={cssColor('dark-grey')}
        justifyContent="space-between"
        alignItems="center"
        borderTopLeftRadius="0.8rem"
        borderTopRightRadius="0.8rem"
        pl={3}
        pr={1}
        py={2}
        gap={[2, 3]}
      >
        <Heading id={labelId} color="white" fontSize={[3, 4]}>
          {title}
        </Heading>

        <Button
          hue="white"
          variant="flat"
          circular
          px={2}
          py={2}
          onClick={close}
        >
          <IconClose />
        </Button>
      </Flex>

      <Box p={3}>{children}</Box>
    </BaseDialog>
  );
};

export default Dialog;
