import { Text } from '@odo/components/elements/typography';
import { NavItem } from '@odo/components/layouts/app-container';
import { useCurrentProduct } from '@odo/contexts/product-editor';

const Title = () => {
  const currentProduct = useCurrentProduct();

  // return an empty element to keep the peace
  if (!currentProduct?.id && !currentProduct?.name) return <span />;

  return (
    <NavItem overflow="hidden" px={[2, 3]} py={0} style={{ cursor: 'unset' }}>
      <Text
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {!!currentProduct?.id && (
          <>
            ID: <b style={{ fontWeight: 700 }}>{currentProduct.id}</b>
          </>
        )}
        {!!currentProduct?.id && currentProduct?.name && (
          <>&nbsp;&#183;&nbsp;</>
        )}
        {!!currentProduct?.name && <>{currentProduct.name}</>}
      </Text>
    </NavItem>
  );
};

export default Title;
