import Card from '@odo/components/elements/card';
import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { cssColor } from '@odo/utils/css-color';
import {
  FaCloudUploadAlt as IconUpload,
  FaRegEyeSlash as IconEyeClosed,
} from 'react-icons/fa';
import { TbTrash as IconTrash } from 'react-icons/tb';
import type { EditorProductImage } from '@odo/types/portal';
import { isNewId } from '@odo/utils/uuid';
import {
  getImageSrc,
  isImageElement,
} from '@odo/screens/deal/editor/images-and-videos/helpers';
import Tooltip from '@odo/components/widgets/tooltip';
import styled from '@odo/lib/styled';
import { memo } from 'react';

const IndicatorWrapper = styled(Flex)`
  box-shadow: 1px 2px 4px -2px hsl(240deg 33.33% 80% / 50%),
    1px 2px 8px -2px hsl(240deg 33.33% 80% / 25%);
`;

IndicatorWrapper.defaultProps = {
  bg: cssColor('foreground'),
  borderRadius: '50%',
  p: 1,
};

const ExcludedImageIndicator = () => (
  <Tooltip showDelay={500} content={() => 'Image will not be shown'}>
    <IndicatorWrapper>
      <IconEyeClosed color={cssColor('palette-blue')} size={18} />
    </IndicatorWrapper>
  </Tooltip>
);

const NewImageIndicator = () => (
  <Tooltip showDelay={500} content={() => 'Image will be uploaded on save'}>
    <IndicatorWrapper>
      <IconUpload color={cssColor('palette-turquoise')} size={18} />
    </IndicatorWrapper>
  </Tooltip>
);

// increasing padding by 1px and reducing size by 2px because the icon is just a bit bigger than it's peers
const DeletedImageIndicator = () => (
  <Tooltip showDelay={500} content={() => 'Image will be deleted on save'}>
    <IndicatorWrapper p="5px">
      <IconTrash color={cssColor('palette-pink')} size={16} />
    </IndicatorWrapper>
  </Tooltip>
);

export const ImagePreviewPrimitive = memo(
  ({
    url,
    file,
    dimensions,
  }: {
    // NOTE: we require the specific fields instead of the entire object
    // so that our memoization can work at full efficacy (eg. changes to position don't cause re-renders here)
    url: EditorProductImage['url'];
    file: EditorProductImage['file'];
    dimensions: number;
  }) => (
    <Card overflow="hidden" maxWidth="min-content" mainProps={{ px: 0, py: 0 }}>
      <Flex
        width={`${dimensions}px`}
        height={`${dimensions}px`}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundImage:
            'linear-gradient(45deg, hsl(206deg 14% 10% / 10%) 25%, transparent 25%), linear-gradient(135deg, hsl(206deg 14% 10% / 10%) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, hsl(206deg 14% 10% / 10%) 75%), linear-gradient(135deg, transparent 75%, hsl(206deg 14% 10% / 10%) 75%)',
          backgroundPosition: '0 0, 10px 0, 10px -10px, 0px 10px',
          backgroundSize: '20px 20px',
        }}
      >
        <img
          src={getImageSrc({ url, file, maxWidth: dimensions + 125 })}
          // NOTE: using the label reduces the efficacy of our memoization and we don't need unique alt text here
          alt="Preview"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: `${dimensions + 25}px`,
            height: 'auto',
            objectFit: 'contain',
          }}
          onLoad={e =>
            e.target instanceof Element &&
            isImageElement(e.target) &&
            // image urls generated with createObjectURL need to be revoked and will have the `blob:` protocol
            e.target.src.startsWith('blob:') &&
            URL.revokeObjectURL(e.target.src)
          }
          draggable="false"
        />
      </Flex>
    </Card>
  )
);

const ImagePreview = ({
  image,
  dimensions,
}: {
  image: EditorProductImage;
  dimensions: number;
}) => (
  <Grid>
    <GridItem gridColumn={1} gridRow={1}>
      <ImagePreviewPrimitive
        url={image.url}
        file={image.file}
        dimensions={dimensions}
      />
    </GridItem>

    <GridItem
      gridColumn={1}
      gridRow={1}
      style={{ position: 'relative', pointerEvents: 'none' }}
    >
      {!!image.isHidden && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: 'hsla(0deg 0% 100% / 0.75)',
            zIndex: 1,
          }}
        />
      )}

      <Flex
        justifyContent="flex-end"
        p={[1, 2]}
        gap={[1, 2]}
        style={{
          zIndex: 2,
          position: 'relative',
          pointerEvents: 'auto',
        }}
      >
        {!!image.isHidden && <ExcludedImageIndicator />}
        {isNewId(image.id) && !image.shouldDelete && <NewImageIndicator />}
        {!!image.shouldDelete && <DeletedImageIndicator />}
      </Flex>
    </GridItem>
  </Grid>
);

export default ImagePreview;
